import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container } from '../../src/components/Grid';
import { media } from '../../src/styles/media';
import Card from '../../src/components/Card/Card';
import { Title } from '../../src/components/common/styles';

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  min-height: 100vh;
  color: ${({ theme }) => theme.color.brand.white};
  background: ${({ backgroundUrl, theme }) =>
    backgroundUrl
      ? `${
          theme.color.brand.brown
        } url('${backgroundUrl}') no-repeat center center`
      : theme.color.brand.brown};
  background-size: cover;
`;

const AbsoluteContent = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 50px;
  right: 0;

  ${media.tablet`
    bottom: 60px;
  `};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const VerticalLine = styled.div`
  position: relative;
  width: 1px;
  height: 72px;
  z-index: 3;
  background-color: ${({ theme }) => theme.color.brand.lightGrey};
  margin-top: 23px;
`;

const CardContainer = styled.div`
  position: relative;
  top: -20px;
  z-index: 2;
`;

const PracticalInfos = ({ data }) => {
  const card = {
    title: data?.section_info_card_title,
    description: data?.section_info_card_description,
    ctaLeft: {
      label: data?.section_info_card_cta_l_label,
      url: data?.section_info_card_cta_l_url
    },
    ctaRight: {
      label: data?.section_info_card_cta_r_label,
      url: data?.section_info_card_cta_r_url,
      iconType: 'keynote'
    }
  };

  return (
    <Wrapper backgroundUrl={data?.section_info_bg}>
      <AbsoluteContent>
        <Container>
          <ContentWrapper>
            <Title>{data?.section_info_title}</Title>
            <VerticalLine />
            <CardContainer>
              <Card maxWidth={410} card={card} />
            </CardContainer>
          </ContentWrapper>
        </Container>
      </AbsoluteContent>
    </Wrapper>
  );
};

PracticalInfos.propTypes = {
  data: PropTypes.object
};

export default PracticalInfos;
