import gql from 'graphql-tag';

export default gql`
  query GetHistorySlides {
    getHistorySlides {
      id
      bg_image
      badge_image_fr
      badge_image_en
      order
      detail {
        id
        language_code
        title
        description
        cta_label
        cta_url
      }
    }
  }
`;
