import gql from 'graphql-tag';

export default gql`
  query($featured: Boolean, $offset: Int, $limit: Int) {
    getEvents(featured: $featured, offset: $offset, limit: $limit) {
      id
      visual_desktop
      visual_mobile
      category {
        id
        detail {
          label
          slug
        }
      }
      detail {
        name
        date
        slug
      }
    }
  }
`;
