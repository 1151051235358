import Head from 'next/head';
import React from 'react';
import PropTypes from 'prop-types';
import { Hero } from '../../src/components/Header';
import { Main, Page } from '../../src/components/Layout';
import { withI18N } from '../../src/hoc/index';
import Events from './Events';
import Offers from './Offers';
import HistorySlides from './HistorySlides';
import CategorySlides from './CategorySlides';
import { Query } from 'react-apollo';
import getHomepage from '../../src/apollo/graphql/home/get';
import createContext from '../../src/utils/format/createContext';
import PracticalInfos from './PraticalInfos';
import InstagramFeed from '../../src/components/Feed/InstagramFeed';

class HomePage extends React.PureComponent {
  render() {
    const { i18n } = this.props;

    return (
      <Page homepage>
        <Query query={getHomepage} context={createContext(i18n.language)}>
          {({ loading, error, data }) => {
            if (!loading && !error && data && data.getHomepage) {
              const homepageData = data.getHomepage;
              const homepageDetail = homepageData?.detail;
              const heroData = {
                section_presentation_video:
                  homepageData?.section_presentation_video,
                section_presentation_bg: homepageData?.section_presentation_bg,
                main_title: homepageDetail?.main_title,
                sub_title: homepageDetail?.sub_title,
                description: homepageDetail?.description,
                cta_label: homepageDetail?.cta_label,
                cta_url: homepageDetail?.cta_url
              };
              const practicalInfosDetail = {
                section_info_bg: homepageData?.section_info_bg,
                section_info_title: homepageDetail?.section_info_title,
                section_info_card_title:
                  homepageDetail?.section_info_card_title,
                section_info_card_description:
                  homepageDetail?.section_info_card_description,
                section_info_card_cta_r_label:
                  homepageDetail?.section_info_card_cta_r_label,
                section_info_card_cta_r_url:
                  homepageDetail?.section_info_card_cta_r_url,
                section_info_card_cta_l_label:
                  homepageDetail?.section_info_card_cta_l_label,
                section_info_card_cta_l_url:
                  homepageDetail?.section_info_card_cta_l_url
              };

              return (
                <>
                  <Head>
                    <title key="title">Grand Hôtel-Dieu</title>
                  </Head>
                  <Hero data={heroData} />
                  <Main>
                    <CategorySlides
                      title={homepageDetail?.section_category_title}
                      backgroundUrl={homepageData?.section_category_bg_layer1}
                    />

                    <Offers title={homepageDetail?.section_offers_title} />

                    <HistorySlides
                      title={homepageDetail?.section_historical_title}
                    />

                    <Events title={homepageDetail?.section_events_title} />

                    <PracticalInfos data={practicalInfosDetail} />

                    <InstagramFeed
                      title={homepageDetail?.section_social_title}
                    />
                  </Main>
                </>
              );
            }

            return null;
          }}
        </Query>
      </Page>
    );
  }
}

HomePage.propTypes = {
  i18n: PropTypes.object
};

export default withI18N()(HomePage);
