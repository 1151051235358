import gql from 'graphql-tag';

export default gql`
  query GetHomepage {
    getHomepage {
      id
      section_presentation_video
      section_presentation_bg
      section_category_bg_layer1
      section_category_bg_layer2
      section_info_bg
      detail {
        id
        main_title
        sub_title
        description
        section_category_title
        section_historical_title
        section_info_title
        section_info_card_title
        section_info_card_description
        section_info_card_cta_r_label
        section_info_card_cta_r_url
        section_info_card_cta_l_label
        section_info_card_cta_l_url
        section_social_title
        section_events_title
        section_offers_title
      }
    }
  }
`;
