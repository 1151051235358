import React from 'react';

import { Container } from '../../src/components/Grid';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import createContext from '../../src/utils/format/createContext';
import dynamic from 'next/dynamic';
import getEvents from '../../src/apollo/graphql/event/getEvents';
import { media } from '../../src/styles/media';
import styled from 'styled-components';
import { translate } from 'react-i18next';
import useMobile from '../../src/utils/useMobile';
import ButtonWithLine from '../../src/components/Button/ButtonWithLine';

const EventSlidesCarousel = dynamic(
  import('../../src/components/Carousel/EventSlidesCarousel'),
  {
    // eslint-disable-next-line react/display-name
    loading: () => <div />,
    ssr: false
  }
);

const Wrapper = styled.div`
  padding-top: 150px;
  padding-bottom: 45px;
  background: ${({ theme }) => theme.color.brand.white};
  background-size: cover;
`;

const Title = styled.h2`
  position: relative;
  z-index: 2;
  font-family: ${({ theme }) => theme.font.brand.secondary};
  font-size: ${({ theme }) => theme.font.homepage.coverTitle.mobileFontSize};
  line-height: ${({ theme }) =>
    theme.font.homepage.coverTitle.mobileLineHeight};
  text-align: center;
  color: ${({ theme }) => theme.color.brand.lightGrey2};
  margin-bottom: 140px;

  ${media.tablet`
    font-size: ${({ theme }) => theme.font.homepage.coverTitle.fontSize};
    line-height: ${({ theme }) => theme.font.homepage.coverTitle.lineHeight};
    margin-bottom: 0px;
  `};
`;

const ButtonWrapper = styled.div`
  margin-top: 60px;
`;

const Events = ({ i18n, t, title }) => {
  const { isMobile } = useMobile();

  return (
    <Query
      query={getEvents}
      variables={{ featured: true, limit: 3 }}
      context={createContext(i18n.language)}
    >
      {({ loading, error, data }) => {
        if (!loading && !error && data && data.getEvents) {
          const events = data.getEvents;

          return (
            <Wrapper>
              <Container>
                <Title>{title}</Title>

                <EventSlidesCarousel slides={events} isMobile={isMobile} />

                <ButtonWrapper>
                  <ButtonWithLine href={t('common:brands.navigation.events')}>
                    {t('common:home.events.button')}
                  </ButtonWithLine>
                </ButtonWrapper>
              </Container>
            </Wrapper>
          );
        }
        return null;
      }}
    </Query>
  );
};

Events.propTypes = {
  i18n: PropTypes.object,
  t: PropTypes.func,
  title: PropTypes.string.isRequired
};

export default translate()(Events);
