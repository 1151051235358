import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from './Button';

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Line = styled.span`
  display: block;
  height: 30px;
  width: 1px;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.color.brand.lightGrey2};
`;

const ButtonWithLine = ({
  href,
  children,
  onClick,
  disabled,
  iconType,
  textColor
}) => {
  return (
    <Root>
      <Line />
      <Button
        href={href}
        onClick={onClick}
        disabled={disabled}
        iconType={iconType}
        textColor={textColor}
      >
        {children}
      </Button>
    </Root>
  );
};

ButtonWithLine.propTypes = {
  href: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  iconType: PropTypes.string,
  textColor: PropTypes.string
};

export default ButtonWithLine;
