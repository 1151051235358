import React, { useState } from 'react';

import { Container } from '../../src/components/Grid';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import createContext from '../../src/utils/format/createContext';
import dynamic from 'next/dynamic';
import getHistorySlides from '../../src/apollo/graphql/home/getHistorySlides';
import { media } from '../../src/styles/media';
import styled from 'styled-components';
import { translate } from 'react-i18next';
import useMobile from '../../src/utils/useMobile';

const HistorySlidesCarousel = dynamic(
  import('../../src/components/Carousel/HistorySlidesCarousel'),
  {
    // eslint-disable-next-line react/display-name
    loading: () => <div />,
    ssr: false
  }
);

const Wrapper = styled.div`
  position: relative;
  padding-top: 150px;
  padding-bottom: 88px;
  overflow: hidden;
  background: ${({ theme }) => theme.color.brand.lightBrown};

  ${media.tablet`
    padding-top: 280px;
    padding-bottom: 105px;
  `};
`;

const Title = styled.h2`
  position: relative;
  z-index: 2;
  font-family: ${({ theme }) => theme.font.brand.secondary};
  font-size: ${({ theme }) => theme.font.homepage.coverTitle.mobileFontSize};
  line-height: ${({ theme }) =>
    theme.font.homepage.coverTitle.mobileLineHeight};
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 140px;

  ${media.tablet`
    font-size: ${({ theme }) => theme.font.homepage.coverTitle.fontSize};
    line-height: ${({ theme }) => theme.font.homepage.coverTitle.lineHeight};
    margin-bottom: 20px;
  `};
`;

const StyledContainer = styled(Container)`
  position: unset;
`;

const HistorySlides = ({ i18n, title }) => {
  const { isMobile } = useMobile();

  return (
    <Query query={getHistorySlides} context={createContext(i18n.language)}>
      {({ loading, error, data }) => {
        if (!loading && !error && data && data.getHistorySlides) {
          const historySlides = data.getHistorySlides;

          return (
            <Wrapper>
              <StyledContainer>
                <Title>{title}</Title>
                <HistorySlidesCarousel
                  slides={historySlides}
                  isMobile={isMobile}
                />
              </StyledContainer>
            </Wrapper>
          );
        }
        return null;
      }}
    </Query>
  );
};

HistorySlides.propTypes = {
  i18n: PropTypes.object,
  title: PropTypes.string.isRequired
};

export default translate()(HistorySlides);
