import gql from 'graphql-tag';

export default gql`
  query GetOffers($orderBy: String) {
    getOffers(orderBy: $orderBy) {
      id
      shop_id
      shop {
        category_id
        type
        name
        slug
        logo1Desk
        logo2Desk
        category {
          detail {
            slug
          }
        }
      }
      page_builder_id
      pageBuilder {
        id
        blocks
        detail {
          title
          slug
        }
      }
      image
      detail {
        title
        description
        offer_date
        order
        cta_label
        cta_url
      }
    }
  }
`;
